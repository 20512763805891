<template>
  <div class="balanceRecord">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>余额记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>记录类型:</span>
      <el-select
        @clear="onclear"
        v-model="list.type"
        placeholder="请选择记录类型"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span>时间：</span>
        <el-date-picker
          @change="onChange"
          v-model="addTime"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      <el-button type="primary" @click="getdetail()">查找</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
      <el-button type="primary" @click="createForm()">生成报表</el-button>

    </div>
    <el-table border :data="detailList" style="width: 100%">
      <el-table-column prop="cur" label="修改前" width="width">
      </el-table-column>
      <el-table-column prop="change" label="修改金额" width="width">
      </el-table-column>
      <el-table-column prop="end" label="修改后" width="width">
      </el-table-column>
      <el-table-column prop="type" label="修改类型" width="width">
      </el-table-column>
      <el-table-column prop="coding" label="订单号" width="width">
      </el-table-column>
      <el-table-column prop="remark" label="备注" width="width">
      </el-table-column>
      <el-table-column prop="addTime" label="修改时间" width="width">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { balanceInfo,selectExcel } from "../../api/vip";
export default {
  name: "balanceRecord",
  props: {
    userId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      options: [
        { value: 0, label: "全部" },
        { value: 1, label: "订单消费" },
        { value: 2, label: "充值" },
        { value: 3, label: "万小店收益" },
        { value: 4, label: "提现" },
        { value: 5, label: "管理员增加" },
        { value: 6, label: "退款" },
        { value: 7, label: "管理员减少" },
        { value: 8, label: "拒绝提现返回" },
        { value: 9, label: "订单退款" },
        { value: 10, label: "邀请下单奖励" },
        { value: 11, label: "扣税" },
      ],
      list: {
        type: 0,
        currentPage: 1,
        pageSize: 5,
        beginTime:'',
        endTime:'',
      },
      addTime:[],
      listExcel:{
        userId:this.userId,
        beginTime:'',
        endTime:'',
        // changeType:this.list.type
      },
      // pickerOptions: {
      //   shortcuts: [
      //     {
      //       text: "最近一周",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //     {
      //       text: "最近一个月",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //     {
      //       text: "最近三个月",
      //       onClick(picker) {
      //         const end = new Date();
      //         const start = new Date();
      //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      //         picker.$emit("pick", [start, end]);
      //       },
      //     },
      //   ],
      // },
      detailList: [],
      pagination: {},
    };
  },
  created() {
    this.getdetail();
  },
  methods: {
    onclear() {
      this.list.type = null;
    },
    async getdetail() {
      const { data } = await balanceInfo({
        ...this.list,
        userId: Number(this.userId),
      });
      if (data.code == 0) {
        this.pagination = data.pagination;
        this.detailList = data.list;
      } else {
        this.$message, error(data.msg);
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getdetail();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getdetail();
    },
    onChange(value) {

console.log(value);
if (value) {
  this.list.beginTime = value[0];
  this.list.endTime = value[1];
  this.listExcel.beginTime = value[0];
  this.listExcel.endTime = value[1];
} else {
  this.list.beginTime = null;
  this.list.endTime = null;
  this.listExcel.beginTime = null;
  this.listExcel.endTime = null;
  this.addTime = []
}
},
//生成报表
async createForm() {
      if(this.addTime.length == ''){
      this.$message.error('请选择时间')  
      }else{
         const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      let date = +new Date();
      const { data } = await selectExcel({...this.listExcel,changeType:this.list.type});
      let url = window.URL.createObjectURL(data); //转换文件流未url
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", `万旅网小程序V2.0余额记录_${date}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      loading.close()
      // let blob = new Blob([data], { type: "application/vnd.ms-excel" });
      // let downloadElement = document.createElement("a");
      // let href = URL.createObjectURL(blob);
      // downloadElement.href = href;
      // downloadElement.download = `万旅网小程序V20会员报表_${date}`;
      // document.body.appendChild(downloadElement);
      // downloadElement.click();
      // document.body.removeChild(downloadElement);
      // window.URL.revokeObjectURL(href);
      }
     
    },
  },
};
</script>
<style lang="less" scoped>
.balanceRecord {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    span {
      margin: 0 10px 0 40px;
    }
    .el-input {
      width: 200px;
    }
    .el-button {
      margin: 0 20px;
    }
    .el-select {
      width: 150px;
    }
  }
  .el-table {
    margin-top: 50px;
    .el-table__body-wrapper {
      .tbody {
        .el-button {
        }
      }
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>